export const TERMS_OF_SERVICE_PATIENT_MD = `### ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ
**Cập nhật lần cuối: 01/06/2023**
#### GIỚI THIỆU

- Vui lòng đọc kỹ Điều Khoản Sử Dụng này. Bằng việc sử dụng Dịch Vụ (như được định nghĩa dưới đây), bạn đồng ý rằng bạn đã đọc và hiểu Điều Khoản Sử Dụng được áp dụng cho bạn. Điều Khoản Sử Dụng này và các chính sách của Kitor (như được định nghĩa dưới đây) tạo thành thỏa thuận ràng buộc về mặt pháp lý (“Hợp Đồng”) giữa bạn và Kitor (như được định nghĩa dưới đây). Hợp Đồng áp dụng cho việc bạn sử dụng Dịch Vụ (như được định nghĩa dưới đây) được cung cấp bởi Kitor. Nếu bạn không đồng ý Điều Khoản Sử Dụng, vui lòng không sử dụng hoặc tiếp tục sử dụng Ứng Dụng hoặc Dịch Vụ.
- Kitor có thể điều chỉnh các điều khoản trong Hợp Đồng này tại bất cứ thời điểm nào. Các điều chỉnh đó sẽ được thông báo đến bạn thông qua Ứng Dụng và/hoặc địa chỉ email đăng ký của bạn trước thời điểm có hiệu lực ít nhất năm (05) ngày. Phiên bản cập nhật sẽ được đăng tải trên trang web https://kitor.vn/. Bạn có trách nhiệm thường xuyên kiểm tra Điều Khoản Sử Dụng. Việc tiếp tục sử dụng Dịch Vụ sau khi có bất kỳ thay đổi nào, bất kể là bạn đã xem xét hay chưa, sẽ tương đương với việc bạn đã chấp thuận và đồng ý đối với những thay đổi đó.
- Công ty Cổ phần Spring Knowledge Global là một **công ty công nghệ** cung cấp nền tảng cho người dùng để sử dụng dịch vụ được cung cấp bởi các nhà cung cấp bên thứ ba. Vai trò của Kitor là liên kết người dùng với các nhà cung cấp bên thứ ba. Kitor không chịu trách nhiệm đối với các hành động và/hoặc thiếu sót của bất kỳ nhà cung cấp bên thứ ba nào, các trách nhiệm pháp lý liên quan đến dịch vụ phải do nhà cung cấp bên thứ ba chịu. Nhà cung cấp bên thứ ba không phải là đại lý, người lao động hay nhân viên của Kitor và các giải pháp được cung cấp bởi nhà cung cấp bên thứ ba không được xem là được cung cấp bởi Kitor.

#### CÁC ĐỊNH NGHĨA

Trong Điều Khoản Sử Dụng này, các từ sau sẽ có nghĩa như được mô tả dưới đây:

“**Ứng Dụng**” nghĩa là ứng dụng di động được Kitor cung cấp để Người Dùng và Các Nhà Cung Cấp Bên Thứ Ba tải xuống;

“**Kitor**” nghĩa là:

Công ty CP Spring Knowledge Global, một công ty được thành lập tại Việt Nam theo giấy chứng nhận đăng ký doanh nghiệp Số 0313857113, có địa chỉ đăng ký tại 74/13/4 Trương Quốc Dung, Phường 10, Quận Phú Nhuận, Thành phố Hồ Chí Minh, Việt Nam (**Công Ty**).

“**Dữ Liệu Cá Nhân**” là bất kỳ thông tin nào có thể được sử dụng để nhận dạng bạn hoặc từ đó bạn có thể được nhận dạng. Điều này bao gồm nhưng không giới hạn ở tên, quốc tịch, số điện thoại, chi tiết thẻ tín dụng và ngân hàng, sở thích cá nhân, địa chỉ email, hình ảnh của bạn, số nhận dạng do chính phủ cấp, dữ liệu sinh trắc học, chủng tộc, ngày sinh, tình trạng hôn nhân, tôn giáo, thông tin sức khỏe, thông tin xe và bảo hiểm, thông tin tiền sử và tình trạng bệnh lý.

“**Nền Tảng**” nghĩa là nền tảng công nghệ, cổng thông tin hoặc trang web có liên quan của Kitor, khi được sử dụng cùng với Ứng Dụng, cho phép Người Dùng yêu cầu hoặc truy cập các Giải Pháp;

“**Thông Báo Bảo Mật**” nghĩa là thông báo bảo mật của chúng tôi có thể truy cập tại: https://kitor.vn/ được sửa đổi theo thời gian;

“**Dịch Vụ**” nghĩa là việc liên kết Người Dùng với Nhà Cung Cấp Bên Thứ Ba thông qua Ứng Dụng, Nền Tảng và/ hoặc Phần Mềm;

“**Phần Mềm**” nghĩa là bất kỳ phần mềm nào liên kết với Ứng Dụng được cung cấp để tải xuống và cài đặt bởi Kitor;

“**Nhà Cung Cấp Bên Thứ Ba**” nghĩa là các bên thứ ba độc lập cung cấp Giải pháp Thăm khám bệnh từ xa cho Người Dùng thông qua Dịch Vụ;

“**Người Dùng**” nghĩa là bất kỳ người nào sử dụng Ứng Dụng, Non Tảng;

“**Phí Người Dùng**” nghĩa là các khoản phí mà Người Dùng phải trả để nhận được Dịch vụ Thăm khám bệnh từ xa thông qua việc sử dụng Hệ thống Kitor;

“**Dữ Liệu Cá Nhân**” nghĩa là Dữ liệu Cá nhân mà Kitor, hoặc các bên liên kết của Kitor tiết lộ cho Nhà Cung cấp bên Thứ ba xử lý nhằm mục đích thực hiện Hợp Đồng này.

#### CAM ĐOAN, BẢO ĐẢM VÀ CAM KẾT

##### Bằng việc sử dụng Dịch Vụ, bạn cam đoan, bảo đảm/ cam kết rằng:

- Bạn có đầy đủ năng lực pháp lý để ký kết Hợp Đồng và rằng bạn đủ mười tám (18) tuổi. Bạn không thể ký kết Hợp Đồng nếu bạn dưới mười tám (18) tuổi;
- Tất cả thông tin bạn cung cấp là đúng và chính xác;
- Bạn sẽ chỉ sử dụng Ứng Dụng, Nền Tảng và Dịch Vụ cho các dự định và mục đích hợp pháp;
- Bạn sẽ bảo toàn và bảo mật mật khẩu tài khoản của mình hoặc bất kỳ phương thức nhận dạng nào mà chúng tôi cung cấp để bạn sử dụng Dịch Vụ;
- Bạn đồng ý thông báo cho chúng tôi ngay lập tức về bất kỳ việc sử dụng trái phép tài khoản của bạn hoặc bất kỳ vi phạm bảo mật nào khác;
- Bạn sẽ không cố gắng làm gián đoạn hoặc làm tổn hại đến Dịch Vụ, Ứng Dụng và/hoặc Phần Mềm dưới bất kỳ hình thức nào;
- Bạn sẽ không cố gắng khai thác thương mại bất kỳ phần nào của Ứng Dụng mà không có sự cho phép của chúng tôi, bao gồm nhưng không giới hạn sửa đổi bất kỳ nội dung nào của Ứng Dụng theo bất kỳ cách nào, hoặc sao chép, tái bản, hiển thị công khai, phân phối hoặc sử dụng hoặc truyền đạt cho bất kỳ mục đích công cộng hoặc thương mại nào mà không có sự cho phép của chúng tôi;
- Bạn sẽ không cho phép người khác sử dụng danh tính hoặc trạng thái người dùng của bạn và bạn không được chuyển nhượng hoặc chuyển giao tài khoản người dùng của mình cho bất kỳ cá nhân hoặc tổ chức nào khác;
- Bạn sẽ cung cấp cho chúng tôi các thông tin nhận dạng như họ và tên, địa chỉ liên lạc, số điện thoại, địa chỉ email, số tài khoản… hoặc bất kỳ tài liệu, sự cho phép, giấy phép hoặc phê duyệt nào khác mà chúng tôi có thể yêu cầu một cách hợp lý, theo quy định pháp luật hoặc của cơ quan nhà nước có thẩm quyền, để cung cấp Dịch Vụ;
- Bạn sẽ không sử dụng Ứng Dụng để gửi và lưu trữ bất kỳ tài liệu hoặc thông tin trái phép nào hoặc phục vụ các mục đích lừa đảo;
- Bạn sẽ không sử dụng Ứng Dụng và/ hoặc Phần Mềm để gây phiền toái hoặc hành xử theo các cách thức không phù hợp hoặc thiếu tôn trọng đối với Kitor hoặc bất kỳ bên thứ ba nào;
- Khi sử dụng Dịch Vụ, bạn đồng ý tuân thủ tất cả các luật áp dụng cho bạn và/ hoặc việc bạn sử dụng Dịch Vụ;
- Bạn sẽ không sao chép hoặc phân phối Phần Mềm hoặc nội dung khác mà không có sự cho phép bằng văn bản từ Kitor;
- Bạn sẽ cung cấp thông tin chính xác, cập nhật và đầy đủ theo yêu cầu của Dịch Vụ và có trách nhiệm duy trì và cập nhật thông tin của bạn một cách kịp thời để đảm bảo rằng các thông tin này luôn chính xác, cập nhật và đầy đủ vào mọi thời điểm trong thời hạn của Hợp Đồng này. Bạn đồng ý rằng Kitor có thể tin cậy vào tính chính xác, cập nhật và đầy đủ của các thông tin mà bạn cung cấp. Bạn xác nhận rằng nếu các thông tin về bạn là không đúng, thiếu chính xác, chưa cập nhật hoặc không đầy đủ trên bất kỳ phương diện nào thì Kitor có quyền, nhưng không phải nghĩa vụ, chấm dứt Hợp Đồng này và việc sử dụng Dịch Vụ của bạn bất cứ lúc nào bằng một thông báo được gửi qua Ứng Dụng, số điện thoại hoặc địa chỉ email đăng ký của bạn;
- Bạn sẽ chỉ sử dụng một điểm truy cập hoặc tài khoản dữ liệu mà bạn được phép sử dụng;
- Bạn đồng ý rằng Dịch Vụ có thể bị ảnh hưởng bởi một số yếu tố khách quan (đường truyền mạng, giờ cao điểm, ảnh hưởng của thời tiết…) dẫn đến chất lượng và tính khả dụng của Dịch Vụ có thể không được đảm bảo xuyên suốt, Công Ty sẽ nỗ lực trong khả năng tốt nhất của mình trong quá trình cung cấp Dịch Vụ cho bạn;
- Bạn đồng ý rằng việc sử dụng Dịch Vụ của bạn phải tuân thủ Thông Báo Bảo Mật của Kitor, có thể được sửa đổi theo thời gian phù hợp với pháp luật hiện hành. Bản cập nhật của Thông Báo Bảo Mật được đăng tải trên trang web https://kitor.vn/ và sẽ được thông báo cho bạn thông qua Ứng Dụng và/ hoặc địa chỉ email đã đăng ký của bạn ít nhất năm (05) ngày làm việc trước ngày có hiệu lực;
- Bạn đồng ý hỗ trợ Kitor trong bất kỳ cuộc điều tra nội bộ hoặc bên ngoài nào mà Kitor có thể yêu cầu để tuân thủ các luật hoặc quy định hiện hành; và
- Bạn đồng ý nhận hoàn toàn trách nhiệm và nghĩa vụ đối với toàn bộ tổn thất hoặc thiệt hại được gây ra cho chính bản thân bạn, Kitor hoặc bất kỳ bên thứ ba nào là kết quả của việc bạn vi phạm Hợp Đồng này mà không phải do lỗi của Kitor hoặc Nhà Cung Cấp Bên Thứ Ba.
- Bạn đồng ý sẽ không sử dụng các thiết bị hoặc ứng dụng đã bị chỉnh sửa với ý định nhầm lẩn tránh việc phát hiện hoặc hỗ trợ các hoạt động gian lận với Kitor hoặc để làm gián đoạn các tính năng vốn có của Ứng Dụng.

##### Nếu bạn là Nhà Cung Cấp Bên Thứ Ba, bạn cam đoan, đảm bảo/ cam kết thêm rằng:

- Nếu áp dụng, Bạn có đủ kiến thức và kinh nghiệm để đưa ra các thông tin/ hướng dẫn, chẩn đoán phù hợp với từng trường hợp bệnh của Người dùng (Bệnh nhân) đăng kí dịch vụ Thăm khám bệnh từ xa và Bạn có và sẽ duy trì chứng chỉ hành nghề có hiệu lực và Bạn có và sẽ duy trì tất cả các giấy phép, phê duyệt, phê chuẩn và chấp thuận khác cần thiết để cung cấp Dịch Vụ Thăm khám bệnh  theo yêu cầu của Luật Áp Dụng.
- Nếu áp dụng, Bạn sẽ nhanh chóng cung cấp cho Công Ty các tài liệu hoặc thông tin bổ sung mà Công Ty yêu cầu, bao gồm nhưng không giới hạn ở tài liệu về nhân thân, chứng chỉ bồi dưỡng chức danh hoặc chứng chỉ hành nghề khám- chữabệnh, giấy khám sức khỏe cá nhân, giấy xét nghiệm ma túy, lý lịch tư pháp.
- Nếu áp dụng, bạn sẽ sử dụng các kiến thức/ kinh nghiệm cũng như phương pháp thích hợp cho việc cung cấp dịch vụ.
- Bạn sẽ hoàn toàn chịu trách nhiệm đối với bất kỳ và mọi khiếu nại, phán quyết và nghĩa vụ pháp lý phát sinh từ bất kỳ tai nạn, tổn thất hay thiệt hại nào bao gồm, nhưng không giới hạn ở thương tích cá nhân, tử vong, tổn thất toàn bộ và thiệt hại về tài sản do hoặc bị cáo buộc là kết quả của dịch vụ Thăm khám bệnh từ xa do bạn cung cấp;
- Bạn phải tuân thủ mọi pháp luật của nước sở tại liên quan đến hoạt động cung cấp dịch vụ Thăm khám bệnh từ xa và sẽ tự chịu trách nhiệm đối với bất kỳ hành vi vi phạm nào đối với pháp luật của nước sở tại;
- Bạn sẽ không liên hệ với Người Dùng cho các mục đích khác ngoài mục đích liên quan đến Dịch Vụ;
- Bạn không được tra cứu ngược, theo dõi hoặc tìm cách theo dõi bất kỳ thông tin nào về bất kỳ người dùng hoặc khách truy cập nào khác vào Ứng Dụng hoặc bất kỳ khách hàng nào khác của Kitor, bao gồm nhưng không giới hạn bất kỳ tài khoản người dùng nào không thuộc sở hữu của bạn, vào nguồn hoặc khai thác Ứng Dụng hoặc bất kỳ dịch vụ hoặc thông tin nào có sẵn hoặc được cung cấp bởi hoặc thông qua Ứng Dụng, theo bất kỳ cách nào nhằm mục đích tiết lộ bất kỳ thông tin nào, bao gồm nhưng không giới hạn thông tin nhận dạng cá nhân, ngoài thông tin của bạn, như được cung cấp bởi Ứng Dụng;
- Bạn đồng ý thực hiện các xét nghiệm ma túy ngẫu nhiên do Kitor, đại lý hoặc người được chỉ định của Kitor sắp xếp hoặc thực hiện;
- Bạn ý thức rõ rằng khi phản hồi Người Dùng, mức cước viễn thông tiêu chuẩn sẽ được áp dụng và sẽ do bạn tự thanh toán; 
- Trong phạm vi tối đa được luật cho phép, việc Bạn cung cấp Dịch Vụ Thăm khám bệnh từ xa cho Người Dùng theo Hợp Đồng Khám chữa bệnh và Điều Khoản Sử Dụng Của Người Dùng tạo ra mối quan hệ trực tiếp giữa Bạn và Người Dùng, trong đó Công Ty không phải là một bên tham gia vào quan hệ đó. Do đó Công Ty không chịu trách nhiệm hay nghĩa vụ nào về các hành vi hoặc thiếu sót của Người Dùng liên quan đến Bạn. Bạn chịu hoàn toàn trách nhiệm đối với các nghĩa vụ hay trách nhiệm đối với Người Dùng.
- Phụ thuộc vào thỏa thuận giữa Công Ty và Bạn, Công Ty có quyền xử lý các khiếu nại của Người Dùng thông qua quy trình xử lý khiếu nại của Công Ty, hoặc các khiếu nại Bạn có thể có đối với một Thường Dùng bất kỳ. Công Ty bảo lưu quyền chuyển các khiếu nại đó cho Bạn một cách trực tiếp và có thể lựa chọn hỗ trợ thảo luận với Người Dùng. Bạn đồng ý hợp tác đầy đủ với quy trình xử lý này, và gửi các khiếu nại Bạn có thể có thông qua Hệ thống Kitor.

##### Nếu bạn là Người Dùng, Bạn cam đoan, đảm bảo/ cam kết thêm rằng:

- Bạn sẽ tuân thủ các Chính Sách của Kitor;
- Việc bạn sử dụng Dịch vụ là để phục vụ riêng cho bạn, sử dụng cá nhân hoặc, nếu được phép, sử dụng cho một người khác ít nhất mười hai (12) tuổi (“**Trẻ Vị Thành Niên**”), trong trường hợp đó bạn sẽ chịu trách nhiệm chính đối với Trẻ Vị Thành Niên;
- Bạn sẽ không sử dụng Ứng Dụng, Nền Tảng và/hoặc Phần mềm để làm phiền, quấy rối, quấy nhiễu hoặc thực hiện đặt các dịch vụ giả mạo;
- Nếu áp dụng, bạn đồng ý cung cấp đầy đủ và chính xác thông tin tiền sử/ tình trạng bệnh khi yêu cầu Dịch Vụ. Bạn cũng thừa nhận và đồng ý rằng nếu thông tin về tiền sử/ tình trạng bệnh là không đúng sự thật hoặc không chính xác, nhà cung cấp dịch vụ Thăm khám bệnh từ xa bên thứ ba có quyền hủy yêu cầu của bạn và bạn có thể bị tính phí dịch vụ cho lần đó;
- Bạn sẽ không liên hệ với Nhà Cung Cấp Bên Thứ Ba vì những mục đích khác ngoài mục đích sử dụng Dịch Vụ trừ trường hợp thực hiện quyền khiếu nại theo quy định pháp luật;
- Bạn không được cố ý hoặc vô ý gây ra hoặc cố gắng gây thiệt hại cho Nhà Cung Cấp Bên Thứ Ba;
- Nếu áp dụng, bạn sẽ không tạo hoặc biên dịch, trực tiếp hoặc gián tiếp, bất kỳ bộ sưu tập, biên dịch hoặc thư mục khác từ bất kỳ nội dung nào được hiển thị trên Ứng Dụng hoặc Nền Tảng ngoại trừ việc sử dụng cá nhân, phi thương mại của bạn;
- Nếu áp dụng, bạn sẽ không sao chép bất kỳ nội dung nào được hiển thị thông qua Ứng Dụng hoặc Nền Tảng, bao gồm mọi đánh giá và nội dung sản phẩm của bên thứ ba, để tái bản ở bất kỳ định dạng hoặc phương tiện nào;
- Bạn thừa nhận và đồng ý rằng chỉ một (1) tài khoản được đăng ký trên một thiết bị;
-  Bạn ý thức rõ rằng khi yêu cầu Giải Pháp bằng SMS hoặc bằng cách sử dụng Dịch Vụ, mức cước viễn thông tiêu chuẩn sẽ được áp dụng; và
- Bạn đồng ý rằng Kitor có thể, tùy theo quyết định riêng của mình, xem một tài khoản là không hoạt động nếu không có giao dịch nào được thực hiện trên tài khoản người dùng của bạn trong thời gian sáu (6) tháng kể từ ngày có giao dịch cuối cùng và hủy kích hoạt hoặc hạn chế quyền truy cập vào tài khoản người dùng của bạn.

#### KHẢ NĂNG TƯƠNG THÍCH

Các kiểu hoặc phiên bản khác nhau của bộ định tuyến, trình duyệt và thiết bị có thể có chương trình cơ sở hoặc cài đặt không tương thích với Ứng Dụng. Mặc dù chúng tôi liên tục phát triển Ứng Dụng để hỗ trợ tất cả các thiết bị và kiểu máy được sử dụng phổ biến ở các thị trường và tất cả các trình duyệt nơi Ứng Dụng có thể được truy cập từ đó, chúng tôi không đảm bảo tính tương thích của Ứng Dụng với các thiết bị di động cụ thể hoặc phần cứng khác.

#### CẤP VÀ GIỚI HẠN QUYỀN

- Kitor và các bên cấp quyền cho Kitor, khi được áp dụng, cấp cho bạn một giấy phép có thể hủy ngang, không độc quyền, không thể chuyển nhượng, hạn chế để sử dụng và truy cập vào Ứng Dụng và/hoặc Phần Mềm để sử dụng Dịch Vụ, phụ thuộc vào các điều khoản và điều kiện của Hợp Đồng này. Tất cả các quyền không cấp cho bạn một cách cụ thể sẽ được bảo lưu bởi Kitor và các bên cấp quyền cho Kitor.
- Bạn không được:
    - cấp quyền, cấp lại quyền, bán, bán lại, chuyển giao, chuyển nhượng, phân phối hoặc khai thác thương mại hoặc cung cấp cho bất kỳ bên thứ ba nào Ứng Dụng và/hoặc Phần Mềm dưới bất kỳ hình thức nào.
    - sửa đổi hoặc tạo ra sản phẩm phái sinh từ Ứng Dụng và/hoặc Phần Mềm;
    - “nhân bản” Ứng Dụng/ Phần Mềm trên bất kỳ máy chủ hoặc thiết bị không dây hoặc internet nào khác;
    - ngoại trừ trong phạm vi các hạn chế đó bị cấm theo luật hiện hành, tháo rời, dịch ngược, sử dụng kỹ sư đảo ngược, giải mã hoặc cố gắng chuyển hóa và mã hóa hoặc trích xuất phần mềm từ Ứng Dụng hoặc bất kỳ phần mềm hoặc dịch vụ nào có sẵn trên hoặc thông qua Ứng Dụng;
    - sử dụng bất kỳ chương trình hoặc tập lệnh thủ công hoặc tự động, bao gồm nhưng không giới hạn, nhện web, trình thu thập web, robot web, kiến web, trình tạo chỉ mục web, bots, virus, hoặc bất kỳ chương trình nào có thể đưa ra nhiều yêu cầu đối với máy chủ mỗi giây (a) gây gánh nặng quá mức hoặc cản trở hoạt động và/ hoặc hiệu suất của Ứng Dụng; (b) để tiến hành các hoạt động khai thác hoặc loại bỏ dữ liệu hoặc (c) bằng mọi cách tái tạo hoặc phá vỡ cấu trúc điều hướng hoặc trình bày Ứng Dụng hoặc nội dung của Ứng Dụng;
    - đăng tải, phân phối hoặc sao chép dưới bất kỳ hình thức nào các tài liệu có bản quyền, nhãn hiệu, hoặc các thông tin độc quyền khác mà không có sự chấp thuận trước của chủ sở hữu;
    - gỡ bỏ bất kỳ thông báo về bản quyền, nhãn hiệu, hay các quyền sở hữu khác có trong Ứng Dụng hoặc Nền Tảng; hoặc
    - sử dụng Ứng Dụng để (a) gửi thư rác hoặc tin nhắn đồng loạt hoặc tin nhắn tự động; (b) gửi hoặc lưu trữ tài liệu xâm phạm, khiêu dâm, đe dọa, bôi nhọ, hoặc trái pháp luật hoặc mang tính nguy hại, bao gồm, nhưng không giới hạn, các tài liệu có hại cho trẻ em hoặc vi phạm quyền riêng tư của bên thứ ba; (c) gửi tài liệu có chứa virus phần mềm, Trojan hoặc đoạn mã, các tập tin, tập lệnh, gián điệp hoặc chương trình máy tính độc hại; (d) gây cản trở hoặc phá vỡ tính toàn vẹn hoặc hiệu suất của Ứng Dụng hoặc dữ liệu chứa trong đó; hoặc (e) cố gắng truy cập trái phép vào Ứng Dụng hoặc các hệ thống hay mạng lưới liên quan của nó; hoặc (f) giả danh bất kỳ cá nhân hoặc tổ chức nào hoặc mô tả sai mối quan hệ của bạn với một cá nhân hoặc tổ chức; hoặc (g) tham gia vào bất kỳ hành vi có thể làm tổn hại đến uy tín hoặc danh tiếng của chúng tôi.

#### THANH TOÁN

#####  Đối với Nhà Cung Cấp Bên Thứ Ba:

Phí Người Dùng sẽ được phân chia và phân bổ cho Công Ty và Bạn theo Thỏa Thuận Hợp Tác Kinh Doanh. Để làm rõ, căn cứ theo Thỏa Thuận Hợp Tác Kinh Doanh, Công Ty sẽ thay mặt bạn nhận thanh toán từ Người dùng với mỗi lần dịch vụ Thăm khám sức khỏe từ xa được hoàn tất và Công ty sẽ thanh toán lại cho Bạn khoản chi phí mà Bạn được nhận mỗi khi Bạn thiết lập yêu cầu thanh toán trên Hệ thống Kitor.

#####  Đối với Người Dùng:

- Khi bạn đã hoàn thành việc sử dụng Dịch Vụ, bạn được yêu cầu thanh toán đầy đủ cho Kitor bằng cách chọn một trong các phương thức thanh toán có sẵn trên Ứng Dụng. Bất kỳ khoản thanh toán nào theo lựa chọn đó sẽ là tự động và không được hoàn lại.
- Bạn có thể chọn thanh toán cho Nhà Cung Cấp Bên Thứ Ba để cung cấp Giải Pháp bằng thẻ tín dụng hoặc thẻ ghi nợ (“**Thẻ**”) hoặc ví Điện tử MoMo hoặc bất kỳ phương thức thanh toán nào khác có trong Ứng Dụng. Việc sử dụng ví MoMo phải tuân thủ các điều khoản và điều kiện như được đăng trên trang web https://momo.vn/.
- Nếu bạn chọn thanh toán bằng Thẻ, bạn cần đăng ký một Thẻ hợp lệ theo quy định được thể hiện Ứng Dụng.
- Bạn đồng ý rằng Kitor có thể xác minh và chấp nhận thông tin Thẻ của bạn khi bạn đăng ký Thẻ lần đầu với chúng tôi cũng như khi bạn sử dụng Dịch Vụ.
- Bạn đồng ý rằng (i) Kitor có thể thực hiện một lệnh “tạm giữ” hợp lý, đây không phải là một khoản phí bị trừ vào Thẻ của bạn mà chỉ nhằm kiểm chứng phương thức thanh toán Thẻ. Phần “tạm giữ” sẽ hiện trong báo cáo tài khoản của bạn là “chờ xử lý”. Số tiền “tạm giữ” sẽ dựa trên mức giá được hiển thị tại thời điểm bạn yêu cầu dịch vụ từ Nhà Cung cấp bên thứ Ba trên Ứng Dụng. Lệnh “tạm giữ” được thực hiện như là một biện pháp phòng chống bất kỳ việc sử dụng trái phép, giao dịch ngoài hạn mức, hoặc lừa đảo nào đối với Thẻ của bạn; (ii) Khi bạn sử dụng một số loại thẻ thanh toán nhất  định để đặt dịch vụ Kitor, bạn cần phải thanh toán trước khi đơn hàng được hoàn tất, chúng tôi gọi đây là quy trình thanh toán trước giao dịch Kitor. Ứng dụng Kitor sẽ thông báo cho bạn khi đơn hàng được áp dụng quy trình thanh toán trước này.
- Trong trường hợp việc thanh toán bằng Thẻ được xử lý ở nước ngoài, bạn sẽ chịu các chi phí phát sinh từ việc thanh toán này.
- Nếu tính năng tiền boa là khả dụng, bạn có thể lựa chọn cài đặt mặc định trước một số tiền boa theo ý bạn khi đăng ký Dịch Vụ. Số tiền này sẽ tự động được cộng thêm vào Phí Người Dùng sau khi Giải Pháp được cung cấp và được chuyển cho Nhà Cung Cấp Bên Thứ Ba trừ khi bạn chọn thay đổi số tiền hoặc xóa tiền boa.
- Kitor có thể tạm dừng xử lý bất kỳ giao dịch nào hoặc vô hiệu hóa hoặc hạn chế việc sử dụng Thẻ trong trường hợp có bất kỳ lỗi nào trong giao dịch dẫn đến việc từ chối hoặc bồi hoàn từ tổ chức tài chính hoặc khi Kitor một cách hợp lý tin rằng Thẻ đã được sử dụng cho một giao dịch có thể là lừa đảo, bất hợp pháp hoặc liên quan đến bất kỳ hoạt động tội phạm nào hoặc khi Kitor một cách hợp lý tin rằng bạn vi phạm Hợp Đồng này.
- Bạn đồng ý bạn sẽ hợp tác trong việc sàng lọc tội phạm tài chính khi có yêu cầu và hỗ trợ Kitor trong việc tuân thủ các quy định pháp luật áp dụng.
- Bạn có trách nhiệm tự giải quyết các tranh chấp với đơn vị phát hành Thẻ.

#### HỦY DỊCH VỤ ĐỐI VỚI NHÀ CUNG CẤP BÊN THỨ BA:

- Bạn đồng ý rằng tỷ lệ hủy Dịch vụ cao và/ hoặc thường xuyên hoặc bỏ qua các yêu cầu Thăm khám bệnh từ xa của Người Dùng sẽ làm giảm trải nghiệm của Người Dùng và ảnh hưởng tiêu cực đến uy tín và thương hiệu của Kitor.
- Mặc dù bạn có thể hủy Dịch vụ, việc hủy phải dựa trên những lý do hủy có thể chấp nhận như được nêu trong Ứng Dụng. Kitor bảo lưu quyền sửa đổi các lý do hủy có thể chấp nhận vào từng thời điểm. Việc hủy dịch vụ không dựa trên một trong những lý do có thể chấp nhận hoặc bỏ qua một yêu cầu đặt chỗ có thể được tính để xác định xem quyền truy cập Dịch Vụ của bạn có bị hạn chế tạm thời không.

#### NỘI DUNG BỊ CẤM: 

Bạn đảm bảo rằng tất cả Nội Dung Bạn và Người Dùng đã đề xuất:

- là chính xác, không giả mạo hoặc là tin rác;
- không phỉ báng, thô tục, phân biệt đối xử, khiêu khích, xúc phạm, có hại, phản cảm hoặc có thể bị phản đối;
- không chứa đựng nội dung không liên quan như các quan điểm chính trị hoặc các quan điểm cá nhân;
- không đề nghị hoặc thúc đẩy việc bán bất kỳ sản phẩm hoặc dịch vụ bị kiểm soát hoặc bị cấm nào, bao gồm nhưng không giới hạn thuốc lá, ma túy, rượu, cờ bạc, vũ khí, các dịch vụ y tế và các dịch vụ người lớn;
- không đề cập đến, thúc đẩy hoặc mô tả nội dung khiêu dâm, các hình ảnh lạm dụng và/hoặc các hình ảnh của trẻ vị thành niên, bạo lực, khủng bố hoặc bất kỳ hành vi gây hại hoặc hành vi bất hợp pháp nào khác;
- không chứa đựng thông tin cá nhân/thông tin nhạy cảm hoặc hình ảnh của bất kỳ người nào;
- không chứa đựng các hình ảnh của các khu vực được bảo vệ, các địa điểm công cộng không thể tiếp cận hoặc các khu vực bị hạn chế, hoặc các khu vực không được phép chụp ảnh và quay phim (ví dụ: trạm kiểm soát nhập cư, doanh trại quân đội, khu vực bên trong của các nơi ở riêng tư); và
- không được sao chép, đánh cắp và không vi phạm các quyền sở hữu trí tuệ của bên thứ ba.
- Sự chấp nhận, từ chối hoặc loại bỏ: Kitor, tùy theo quyết định của mình và vào bất kỳ lúc nào mà không cần thông báo đến bạn, có thể chấp nhận, từ chối bất kỳ Nội Dung nào đã được đề xuất;

#### CẤP PHÉP

Bằng việc đề xuất :

- bạn hoàn toàn chịu trách nhiệm về hành vi của mình và mọi dữ liệu, văn bản, thông tin, tên, hình ảnh, đồ họa, bức ảnh, biểu tượng và nội dung khác mà bạn đề xuất, bài đăng hiển thị trên hoặc thông qua Ứng Dụng. Kitor không yêu cầu quyền sở hữu đối với bất kỳ Nội Dung nào mà bạn đăng trên hoặc thông qua Ứng Dụng.
- bạn không có nghĩa vụ phải cung cấp bất kỳ nội dung nào cho Kitor. Tuy nhiên, nếu bạn chọn đăng tải hoặc chia sẻ nội dung, bạn tuyên bố và bảo đảm rằng bạn có các sự cho phép cần thiết để đề xuất nội dung đó;
- bạn cấp cho Kitor một giấy phép không hủy ngang, trên toàn thế giới, không độc quyền và miễn phí bản quyền để lưu giữ, tái tạo, phân phối, truyền tải, xuất bản, sử dụng, cấp phép lại, sửa đổi và tạo các tác phẩm phái sinh dựa trên nội dung bạn đề xuất.
- Nội Dung bạn đề xuất mà chứa thông tin thực tế có sẵn công khai, như tên của một doanh nghiệp, không thuộc phạm vi của điều khoản cấp phép này.
- Điều khoản cấp phép này tồn tại cho đến khi nội dung của bạn được bảo vệ bởi các quyền sở hữu trí tuệ.

#### ĐIỀU CHỈNH HOẶC LOẠI BỎ CÁC YÊU CẦU

Bạn có thể gửi các yêu cầu điều chỉnh hoặc loại bỏ các nội dung đã đề xuất thông qua Ứng Dụng. Kitor sẽ đánh giá từng trường hợp cụ thể để xem xét có chấp nhận các yêu cầu đó hay không tùy vào quyết định riêng của Kitor.

#### QUYỀN SỞ HỮU TRÍ TUỆ

Chỉ có Kitor và các bên cấp quyền cho Kitor, khi áp dụng, mới được quyền sở hữu tất cả các quyền, quyền sở hữu và lợi ích, bao gồm tất cả các quyền sở hữu trí tuệ có liên quan, đối với Phần Mềm và/hoặc Ứng Dụng và theo nghĩa rộng hơn là Dịch Vụ và bất cứ đề nghị, ý tưởng, yêu cầu nâng cấp, thông tin phản hồi, khuyến nghị hoặc các thông tin nào khác do bạn hoặc bất kỳ bên nào khác có liên quan đến Dịch Vụ. Điều Khoản Sử Dụng này không tạo nên một thỏa thuận mua bán và không chuyển cho bạn bất kỳ quyền sở hữu nào đối với hoặc liên quan đến Dịch Vụ, Phần Mềm và/hoặc Ứng Dụng, hoặc bất kỳ quyển sở hữu tài sản trí tuệ nào thuộc sở hữu của Kitor và/hoặc các bên cấp li-xăng của Kitor. Tên công ty, logo Kitor, Dịch Vụ, Phần Mềm và/hoặc Ứng Dụng và logo của các nhà cung cấp dịch vụ vận chuyển bên thứ ba và tên sản phẩm gắn liền với Phần Mềm và/hoặc Ứng Dụng là các nhãn hiệu của Kitor hoặc các bên thứ ba, và không được trao quyền hay giấy phép nào để sử dụng chúng. Để tránh hiểu nhầm, thuật ngữ Phần Mềm và Ứng Dụng ở đây là bao gồm toàn bộ các bộ phận, quy trình và thiết kế tạo nên Phần Mềm và Ứng Dụng.

#### THUẾ

##### Đối với Nhà cung cấp bên thứ Ba

- Các khoản thanh toán mà Công Ty trả cho Bạn có thể phải chịu thuế, nghĩa vụ, lệ phí, phí và/hoặc chi phí theo luật hiện hành áp dụng, dù thể hiện bằng đồng tiền nào, có hiệu lực thi hành và liên quan đến các khoản thuế áp dụng trong tương lai mà có thể được quy định vào bất kỳ thời điểm nào.
- Bạn đồng ý rằng Bạn chịu hoàn toàn trách nhiệm đối với các khoản thuế quy định cho thu nhập của chính Bạn phát sinh từ việc Bạn thực hiện Dịch Vụ Thăm khám bệnh từ xa. Bạn cam đoan rằng Bạn sẽ thực hiện các nghĩa vụ cần thiết do cơ quan thuế hoặc cơ quan doanh thu có thẩm quyền quy định liên quan đến việc báo cáo thu nhập của Bạn và nộp thuế dựa trên báo cáo đó.
- Phụ thuộc vào Thỏa Thuận Hợp Tác Kinh Doanh, Bạn đồng ý rằng Công Ty sẽ được Bạn ủy quyền (tuy nhiên luôn phụ thuộc vào điều 8) để thay mặt Bạn kê khai, thu và/hoặc nộp các khoản thuế liên quan, và tiết lộ tất cả các thông tin cần thiết hoặc liên quan cho cơ quan thuế hoặc cơ quan doanh thu có thẩm quyền cho các mục đích đó. Trong phạm vi được ủy quyền hoặc cho phép theo quy định của Luật Áp Dụng, Công Ty sẽ được khấu trừ hoặc giữ lại bất kỳ khoản tiền nào của bạn cho mục đích kê khai, thu và/hoặc nộp thuế liên quan thay mặt Bạn. Trường hợp số dư trong Tài khoản của Bạn không đủ để Công Ty thay mặt Bạn thanh toán bất kỳ khoản thuế liên quan nào, Bạn có nghĩa vụ bổ sung khoản thuế còn thiếu đó cho Công Ty thông qua các kênh và phương thức do Công Ty quy định vào từng thời điểm hoặc nộp khoản còn thiếu bằng cách khác cho cơ quan thuế hoặc cơ quan doanh thu có thẩm quyền theo hướng dẫn của Công Ty.
- Bạn đồng ý thực hiện mọi điều cần thiết và theo yêu cầu của Luật Áp Dụng để cho phép, hỗ trợ và/hoặc bảo vệ Công Ty để yêu cầu hoặc xác minh bất kỳ khoản cấn trừ, bù trừ, chiết khấu hoặc hoàn thuế nào áp dụng đối với số thuế đã nộp hoặc phải nộp liên quan đến Hệ thống.

#### BẢO MẬT

- Bạn phải bảo mật tất cả các thông tin và dữ liệu liên quan đến Kitor, các dịch vụ, sản phẩm, công việc kinh doanh, kế hoạch tiếp thị và quảng bá hoặc các hoạt động khác của Kitor và các công ty liên kết của Kitor được tiết lộ cho bạn bởi Kitor hoặc thay mặt Kitor (bất kể bằng lời nói hay bằng văn bản, trước, tại hoặc sau ngày của Hợp Đồng này) hoặc đã được bạn thu thập gián tiếp hay trực tiếp, từ Kitor hoặc bất kỳ các công ty liên kết nào khác, hoặc đã được tạo ra trong quá trình giao kết Hợp Đồng này. Bạn đảm bảo thêm rằng bạn chỉ sử dụng thông tin bí mật đó để sử dụng Dịch Vụ và không được tiết lộ thông tin đó cho bất kỳ bên thứ ba nào hoặc sử dụng cho bất kỳ mục đích nào khác mà không có sự đồng ý trước bằng văn bản của Kitor.
- Nghĩa vụ bảo mật nêu trên không áp dụng trong trường hợp bạn có thể chỉ ra rằng các thông tin liên quan:
    - đã thuộc sở hữu của bạn vào thời điểm tiếp nhận thông tin;
    - là, hoặc trở thành trong tương lai, thông tin phổ cập mà không phải do lỗi hoặc sai sót của bạn;
    - đã được tiếp nhận từ một bên thứ ba có quyền tiết lộ chúng; hoặc
    - phải tiết lộ theo quy định pháp luật.

#### CHÍNH SÁCH BẢO MẬT VÀ BẢO VỆ DỮ LIỆU

- Kitor thu thập và xử lý Dữ Liệu Cá Nhân của bạn theo Thông Báo Bảo Mật. Thông Báo Bảo Mật áp dụng cho tất cả các Dịch Vụ của Kitor và các điều khoản của nó tạo thành một phần của Hợp Đồng bằng cách tham chiếu đến nó.
- Nếu áp dụng, bạn đồng ý và chấp thuận cho Kitor, các công ty con và bất kỳ công ty liên kết nào của Kitor thu thập, sử dụng, xử lý và tiết lộ Dữ Liệu Cá Nhân như được mô tả thêm trong Thông Báo Bảo Mật.
- Bạn thừa nhận rằng Kitor có thể tiết lộ Dữ Liệu Cá Nhân của các cá nhân khác cho bạn trong quá trình bạn sử dụng Dịch Vụ của Kitor. Bạn cam đoan và bảo đảm rằng bạn sẽ chỉ sử dụng dữ liệu cá nhân đó cho mục đích mà Kitor đã tiết lộ cho bạn và không cho bất kỳ mục đích trái phép nào khác.

#### TƯƠNG TÁC VỚI BÊN THỨ BA

- Bạn xác nhận rằng Ứng Dụng đã sử dụng dữ liệu từ Nguồn Dữ Liệu được bạn cung cấp khi đăng kí tham gia Dịch vụ.
- Trong quá trình sử dụng Dịch Vụ, bạn có thể trao đổi thư từ hoặc giao dịch với các bên thứ ba hiển thị hoặc cung cấp hàng hóa và/hoặc dịch vụ của họ thông qua Nền Tảng hoặc Ứng Dụng. Kitor và bất kỳ công ty liên kết nào của Kitor không xác nhận bất kỳ ứng dụng hoặc trang web nào trên Internet được liên kết thông qua Nền Tảng hoặc Ứng Dụng, và trong mọi trường hợp, Kitor và các công ty liên kết của Kitor sẽ không chịu trách nhiệm cho bất kỳ nội dung, sản phẩm, dịch vụ hoặc tài liệu nào khác trên hoặc có sẵn từ các trang web hoặc nhà cung cấp bên thứ ba đó. Một số nhà cung cấp dịch vụ vận chuyển, hàng hóa và/ hoặc dịch vụ nhất định có thể yêu cầu bạn đồng ý với các điều khoản sử dụng và chính sách bảo mật bổ sung hoặc khác biệt trước khi bạn sử dụng hoặc tiếp cận các hàng hóa hoặc dịch vụ, Kitor không phải là một bên tham gia và từ chối mọi trách nhiệm và/ hoặc nghĩa vụ pháp lý phát sinh từ các thỏa thuận giữa bạn và các nhà cung cấp bên thứ ba. Bạn thừa nhận rằng các Điều khoản sử dụng và chính sách bảo mật bổ sung hoặc khác biệt như vậy có thể áp dụng đối với việc bạn sử dụng các dịch vụ của bên thứ ba đó. Kitor không chịu trách nhiệm đối với bất kỳ thông tin nào bạn cung cấp hoặc ủy quyền cho chúng tôi cung cấp cho bên thứ ba hoặc đối với việc thu thập, sử dụng, tiết lộ các thông tin đó của bên thứ ba.
- Kitor có thể dựa vào quảng cáo và tiếp thị của bên thứ ba được cung cấp thông qua Dịch Vụ và các cơ chế khác để phụ trợ cho Dịch Vụ và/ hoặc để tăng doanh thu. Bằng cách cài đặt và sử dụng Ứng Dụng, bạn đồng ý nhận các quảng cáo và tiếp thị như vậy. Nếu bạn không muốn nhận quảng cáo và tiếp thị nói trên, bạn có thể vào mục cài đặt trên Ứng Dụng và tắt chế độ nhận quảng cáo hoặc vui lòng tham khảo Thông Báo Bảo Mật của chúng tôi để được hướng dẫn hủy đăng ký hoặc cập nhật cài đặt quyền riêng tư của bạn. Bạn đồng ý và cho phép Kitor biên dịch và tiết lộ thông tin liên quan đến bạn và việc bạn sử dụng Dịch Vụ trên cơ sở ẩn danh như một phần của hồ sơ khách hàng hoặc báo cáo hoặc phân tích tương tự. Bạn đồng ý rằng bạn có trách nhiệm thực hiện mọi biện pháp phòng ngừa trong mọi hành động và tương tác với bất kỳ bên thứ ba nào mà bạn tương tác thông qua Dịch Vụ và/ hoặc tài liệu quảng cáo hoặc tiếp thị do bên thứ ba cung cấp thông qua Dịch Vụ
- Chúng tôi có thể bao gồm các siêu liên kết đến các trang web hoặc nội dung khác trên Internet được sở hữu hoặc vận hành bởi các bên thứ ba (“**Liên Kết Của Bên Thứ Ba**“). Liên Kết Của Bên Thứ Ba không thuộc quyền kiểm soát của chúng tôi và chúng tôi không chịu trách nhiệm cho bất kỳ lỗi, thiếu sót, chậm trễ, phỉ báng, bôi nhọ, nói xấu, khiêu dâm, thô tục, không chính xác hoặc bất kỳ tài liệu phản cảm nào có trong nội dung hoặc là hậu quả của việc truy cập, bất kỳ trang web liên kết nào. Siêu liên kết đến bất kỳ trang web hoặc nội dung nào khác không phải là sự chứng thực hoặc xác minh các trang web hoặc nội dung đó và bạn đồng ý rằng bạn hoàn toàn chịu rủi ro trong việc truy cập hoặc sử dụng các trang web hoặc nội dung được liên kết đó

#### BỒI THƯỜNG

Bằng việc đồng ý với Điều Khoản Sử Dụng khi sử dụng Dịch Vụ, bạn đồng ý rằng bạn sẽ bồi thường và giữ Kitor, các bên cấp quyền của Kitor và các chi nhánh, cán bộ, giám đốc, thành viên, nhân viên, luật sư và đại lý của bên đó không bị tổn hại và chống lại mọi khiếu nại, chi phí, thiệt hại, tổn thất, trách nhiệm pháp lý và phí khác (bao gồm phí luật sư và chi phí và/ hoặc hành động pháp lý) phát sinh từ hoặc liên quan đến: (a) việc bạn sử dụng Dịch Vụ, Nền Tảng, Phần Mềm và/ hoặc Ứng Dụng trong giao dịch với Nhà Cung Cấp Bên Thứ Ba hoặc Người Dùng (tùy từng trường hợp), thương nhân, nhà cung cấp, đối tác, nhà quảng cáo và/ hoặc nhà tài trợ bên thứ ba, hoặc (b) vi phạm của bạn đối với bất kỳ Điều Khoản Sử Dụng nào hoặc bất kỳ luật hoặc quy định hiện hành nào, cho dù có hoặc không được tham chiếu ở đây, hoặc (c) vi phạm của bạn đối với bất kỳ quyền nào của bất kỳ bên thứ ba nào, bao gồm Nhà Cung Cấp Bên Thứ Ba hoặc Người Dùng được sắp xếp thông qua Dịch Vụ, hoặc (d) việc bạn sử dụng hoặc sử dụng sai Dịch Vụ, Nền Tảng, Phần Mềm và/ hoặc Ứng Dụng; (e) khi áp dụng quyền sở hữu, sử dụng hoặc vận hành của bạn đối với bất kỳ Phương Tiện nào, bao gồm việc cung cấp Các Giải Pháp cho Người Dùng thông qua Dịch Vụ nếu có. Để làm rõ, việc xác định thiệt hại và mức bồi thường sẽ căn cứ vào các quy định và nguyên tắc của Bộ luật Dân sự hiện hành.

#### MIỄN TRỪ TRÁCH NHIỆM

Kitor không tuyên bố hay bảo đảm dưới bất kỳ hình thức nào, một cách rõ ràng hay ngụ ý, về Các Giải Pháp được cung cấp bởi Nhà Cung Cấp Bên Thứ Ba hoặc bất kỳ Giải Pháp nào được mua thông qua việc sử dụng Dịch Vụ. Bạn đồng ý rằng bạn sẽ chịu mọi rủi ro phát sinh từ việc bạn sử dụng Dịch Vụ và Các Giải Pháp do Nhà Cung Cấp Bên Thứ Ba cung cấp và sẽ không có quyền truy đòi Kitor.

#### CHẬM TRỄ DO INTERNET

DỊCH VỤ, NỀN TẢNG, ỨNG DỤNG VÀ/HOẶC PHẦN MỀM CÓ THỂ CÓ NHỮNG HẠN CHẾ, CHẬM TRỄ, VÀ CÁC VẤN ĐỀ KHÁC XUẤT PHÁT TỪ VIỆC SỬ DỤNG INTERNET VÀ THÔNG TIN LIÊN LẠC THEO PHƯƠNG THỨC ĐIỆN TỬ BAO GỒM VIỆC THIẾT BỊ BẠN SỬ DỤNG HOẶC NHÀ CUNG CẤP DỊCH VỤ VẬN CHUYỂN BÊN THỨ BA SỬ DỤNG BỊ LỖI, KHÔNG CÓ KẾT NỐI, NẰM NGOÀI VÒNG PHỦ SÓNG, BỊ TẮT NGUỒN HOẶC KHÔNG HOẠT ĐỘNG. Kitor KHÔNG CHỊU TRÁCH NHIỆM ĐỐI VỚI BẤT KỲ SỰ TRÌ HOÃN, GIAO HÀNG THẤT BẠI, THIỆT HẠI HOẶC TỔN THẤT DO CÁC SỰ KIỆN NÊU TRÊN GÂY RA.

#### GIỚI HẠN TRÁCH NHIỆM

- Trừ khi có quy định khác, và trong phạm vi tối đa được pháp luật cho phép, bất kỳ khiếu nại nào của bạn chống lại kitor sẽ bị giới hạn ở tổng số tiền mà bạn đã thực tế chi trả và/hoặc kể từ khi bạn sử dụng dịch vụ trong suốt sự kiện làm phát sinh khiếu nại đó. Kitor và/hoặc các bên cấp li-xăng của kitor không chịu trách nhiệm đối với bất kỳ tổn thất, thiệt hại hoặc thương tích phát sinh bởi hoặc gây ra cho bạn hoặc bất kỳ người nào được bạn đặt dịch vụ giúp, bao gồm nhưng không giới hạn ở:
- Tổn thất, thiệt hại hoặc thương tích phát sinh từ hoặc bằng cách khác liên quan đến dịch vụ, nền tảng, ứng dụng và/hoặc phần mềm;
- Việc sử dụng hoặc không có khả năng sử dụng dịch vụ, nền tảng, ứng dụng và/hoặc phần mềm;
- Bất kỳ sự tín nhiệm nào của bạn đối với sự hoàn chỉnh, chính xác và tồn tại của bất kỳ quảng cáo nào; hoặc
- Là kết quả của bất kỳ mối quan hệ hoặc giao dịch nào giữa bạn và bất kỳ nhà cung cấp bên thứ ba, thương nhân, nhà quảng cáo hoặc nhà tài trợ có quảng cáo xuất hiện trên trang web hoặc được chỉ dẫn đến bởi dịch vụ, ứng dụng và/hoặc phần mềm ngay cả khi kitor và/hoặc các bên cấp li-xăng của kitor đã tư vấn trước đó về khả năng xảy ra các thiệt hại.
- Kitor sẽ yêu cầu các nhà cung cấp bên thứ ba cung cấp đầy đủ thông tin theo quy định khi tham gia cung cấp dịch vụ trên nền tảng và/hoặc ứng dụng kitor. Để tránh nhầm lẫn, kitor không và sẽ không tiếp cận hoặc giám sát sự phù hợp, tính pháp lý, khả năng, hoạt động và địa điểm của bất kỳ nhà cung cấp bên thứ ba nào. Tuy nhiên kitor có trách nhiệm loại bỏ khỏi nền tảng và/hoặc ứng dụng kitor những thông tin bán hàng giả, hàng nhái, hàng nhập lậu, hàng vi phạm quyền sở hữu trí tuệ và các hàng hóa, dịch vụ vi phạm pháp luật khác khi phát hiện hoặc nhận được phản ánh có căn cứ xác thực về những thông tin này. Bạn hiểu và đồng ý từ bỏ, giải phóng kitor khỏi các trách nhiệm pháp lý, khiếu nại hoặc thiệt hại phát sinh từ các nhà cung cấp bên thứ ba khi kitor đã thực hiện đầy đủ các nội dung nêu tại khoản này.
- Kitor sẽ không là một bên trong tranh chấp hoặc đàm phán tranh chấp giữa bạn và nhà cung cấp bên thứ ba.
- Bạn tự chịu trách nhiệm đối với các quyết định của mình liên quan đến dịch vụ và sản phẩm được đề xuất thông qua dịch vụ, phần mềm và/hoặc ứng dụng. Ngoại trừ các khiếu nại liên quan đến tính khả dụng của nền tảng và/hoặc ứng dụng Kitor,  
- Bạn hiểu và đồng ý rằng kitor chỉ là đầu mối tiếp nhận, hỗ trợ các bên liên quan giải quyết khiếu nại. Bạn theo đây đồng ý thêm rằng bạn từ bỏ và giải phóng kitor khỏi các trách nhiệm pháp lý, khiếu nại, hoạt động tố tụng  hoặc thiệt hại phát sinh từ việc sử dụng dịch vụ, phần mềm và/hoặc ứng dụng, hoặc bằng cách khác liên quan đến nhà cung cấp bên thứ ba  được giới thiệu đến bạn bởi dịch vụ, phần mềm và/hoặc ứng dụng.
- Chất lượng của các giải pháp được sắp xếp thông qua việc sử dụng dịch vụ hoàn toàn là trách nhiệm của nhà cung cấp bên thứ ba, là bên cuối cùng cung cấp các giải pháp đó cho bạn. Do đó, bạn hiểu rằng bằng cách sử dụng dịch vụ, bạn có thể đối mặt với các tai nạn bệnh tật tiềm ẩn, khả năng bị ảnh hưởng đến sức khỏe, hoặc các vấn đề khó chịu khác, và bạn tự chịu rủi ro đối với việc sử dụng dịch vụ. Bạn hiểu và đồng ý thêm rằng nhà cung cấp dịch vụ  bên thứ ba chịu hoàn toàn trách nhiệm về chất lượng của dịch vụ thăm khám bệnh từ xa được cung cấp đến bạn thông qua ứng dụng. 

#### NGHĨA VỤ CỦA KITOR

- Cung cấp Dịch Vụ theo đúng nội dung Quy chế hoạt động đã đăng ký với Cơ quan nhà nước có thẩm quyền;
- Tuân thủ các quy định pháp luật về thương mại điện tử;
- Cung cấp hoá đơn cước phí qua địa chỉ email của Người Dùng sau mỗi lần dịch vụ Thăm khám bệnh từ xa được hoàn thành.
- Đảm bảo sử dụng đúng Mục Đích các Thông Tin Cá Nhân của Người Dùng quy định tại Hợp Đồng này, việc tiết lộ và/hoặc chuyển giao Thông Tin Cá Nhân chỉ được thực hiện sau khi có sự chấp thuận của Người Dùng;
- Lưu trữ và bảo mật Thông Tin Cá Nhân của Người Dùng cho đến khi có yêu cầu hủy bỏ;
- Tiếp nhận và trực tiếp xử lý các khiếu nại của Người Dùng liên quan đến việc cung cấp và sử dụng Ứng Dụng và/hoặc Phần Mềm trên cơ sở quy định của pháp luật, Điều Khoản Sử Dụng đã công bố.
- Phối hợp với Nhà Cung Cấp Bên Thứ Ba giải quyết các khiếu nại của Người Dùng liên quan đến dịch vụ Thăm khám bệnh từ xa được cung cấp thông qua Ứng Dụng trong phạm vi trách nhiệm của Nhà Cung Cấp Bên Thứ Ba theo quy định của pháp luật;
- Xây dựng hệ thống chăm sóc khách hàng 24/7, đa dạng hoá các kênh tương tác bao gồm liên hệ trực tiếp tổng đài qua tính năng “Gọi đến Kitor” ngay trên ứng dụng, gửi yêu cầu hỗ trợ qua Mục Trợ Giúp**,** trang mạng xã hội Facebook chính thức của Kitor, qua Ứng Dụng và/hoặc các hình thức khác để tiếp nhận, xử lý thông tin khiếu nại, phản ánh, góp ý của người dùng một cách nhanh chóng và kịp thời.
- Tôn trọng và nghiêm túc thực hiện các quy định về bảo vệ quyền lợi người tiêu dùng và bảo vệ dữ liệu cá nhân;
- Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát Dữ Liệu Cá Nhân của Người Dùng, Kitor có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho Người Dùng được biết;
- Thực hiện đầy đủ các nghĩa vụ thuế, tài chính và các nghĩa vụ khác theo quy định của pháp luật; và
- Các nghĩa vụ khác theo quy định của pháp luật.

#### THÔNG BÁO

Kitor có thể gửi thông báo thông qua Ứng Dụng, qua thư điện tử đến địa chỉ thư điện tử của bạn trong dữ liệu của Kitor, hoặc bằng văn bản gửi bằng thư đảm bảo hoặc thư trả trước đến địa chỉ của bạn trong dữ liệu của Kitor. Thông báo đó sẽ được coi là đã gửi tới bạn sau 48 giờ kể từ lúc gửi bưu điện (nếu được gửi bằng thư bảo đảm hoặc thư trả trước) hoặc sau 1 giờ sau khi gửi (nếu gửi bằng thư điện tử). Bạn có thể gửi thông báo cho Kitor (thông báo đó sẽ được coi là đã gửi cho Kitor khi Kitor nhận được) bằng thư gửi qua bưu điện hoặc thư điện tử đã đăng ký đến Kitor sử dụng các thông tin liên hệ như được cung cấp trong Ứng Dụng.

#### CHUYỂN NHƯỢNG

Điều Khoản Sử Dụng này như được thay đổi tại từng thời điểm, sẽ không thể được bạn chuyển nhượng mà không có sự chấp thuận trước bằng văn bản của Kitor. Bạn đồng ý rằng Kitor có quyền chuyển nhượng các quyền và nghĩa vụ của mình được quy định trong Hợp Đồng này cho bất kỳ bên thứ ba nào với điều kiện Kitor phải (i) gửi thông báo về việc chuyển nhượng đến bạn thông qua Ứng Dụng hoặc địa chỉ email đăng ký của bạn và (ii) bên thứ ba cam kết kế thừa toàn bộ quyền và nghĩa vụ của Kitor theo Hợp Đồng này. Trường hợp bạn không đồng ý việc chuyển nhượng và đề nghị thanh lý Hợp Đồng, Kitor sẽ chịu trách nhiệm giải quyết các vấn đề còn tồn đọng, quyền/ nghĩa vụ phát sinh trước thời điểm chấm dứt Hợp Đồng với bạn.

#### GIẢI QUYẾT TRANH CHẤP

- Điều Khoản Sử Dụng này sẽ được điều chỉnh bởi pháp luật Việt Nam, không liên quan đến việc lựa chọn hay mâu thuẫn quy định pháp luật của bất kỳ quyền tài phán nào, và bất kỳ tranh chấp, hành động, khiếu nại hay lý do khởi kiện xuất phát từ hay liên quan đến Điều Khoản Sử Dụng hoặc Dịch Vụ sẽ được giải quyết bởi Trung tâm Trọng tài Quốc tế Việt Nam (“**VIAC**”), phù hợp với các Nguyên tắc Tố tụng Trọng tài của VIAC được sửa đổi, bổ sung tại từng thời điểm (“**Các Nguyên Tắc Tố Tụng Trọng Tài**”) bởi một trọng tài do bạn và Kitor cùng thống nhất chỉ định (“**Trọng Tài Viên**”). Nếu bạn và Kitor không thể thống nhất về một trọng tài, Trọng Tài Viên sẽ được chỉ định bởi Chủ tịch của VIAC theo đúng Các Nguyên Tắc Tố Tụng Trọng Tài. Địa điểm tố tụng trọng tài là Việt Nam, ngôn ngữ trọng tài bằng tiếng Việt và phí Trọng Tài sẽ được chia đều cho và Kitor, trừ khi Trọng Tài yêu cầu phí này được chi trả theo cách khác mà Trọng Tài xác định là cần thiết để điều khoản trọng tài này có thể được thi hành theo pháp luật áp dụng.
- Không phụ thuộc vào các quy định trái với điều này, bất kỳ khiếu nại nào giữa Nhà Cung Cấp Bên Thứ Ba và Người Dùng phải được giải quyết trực tiếp với nhau.

#### MỐI QUAN HỆ

Không có nội dung nào trong Điều Khoản Sử Dụng này được hiểu là tạo ra bất kỳ mối quan hệ đại lý, đối tác hoặc hình thức doanh nghiệp chung nào khác với Kitor.

#### TÍNH RIÊNG BIỆT

Nếu bất kỳ điều khoản nào trong Điều Khoản Sử Dụng này được coi là không hợp lệ hoặc không thể thực thi, thì tính hợp pháp, hiệu lực và thực thi của các điều khoản còn lại sẽ không bị ảnh hưởng hoặc bị suy giảm.

#### KHÔNG TỪ BỎ

Việc Kitor không thực thi bất kỳ quyền hoặc quy định nào trong Điều Khoản Sử Dụng sẽ không cấu thành việc từ bỏ quyền hoặc quy định đó.

#### THỎA THUẬN TOÀN BỘ

Hợp Đồng này bao gồm toàn bộ thỏa thuận giữa bạn và Kitor và thay thế mọi cuộc đàm phán hoặc thảo luận trước đó hoặc đương thời.

#### ĐÌNH CHỈ VÀ CHẤM DỨT

Bạn đồng ý rằng chúng tôi có thể thực hiện bất kỳ hành động nào sau đây, vào bất cứ lúc nào bằng cách gửi thông báo cho bạn thông qua Ứng Dụng hoặc địa chỉ email đã đăng ký của bạn: (i) để sửa đổi, đình chỉ hoặc chấm dứt hoạt động hoặc truy cập vào Ứng Dụng hoặc bất kỳ phần nào của Ứng Dụng (bao gồm quyền truy cập vào Tài Khoản của bạn và/ hoặc tính khả dụng của bất kỳ sản phẩm hoặc dịch vụ nào), vì bất kỳ lý do gì; (ii) để sửa đổi hoặc thay đổi bất kỳ chính sách hoặc điều khoản hiện hành nào; và (iii) làm gián đoạn hoạt động của Ứng Dụng hoặc bất kỳ phần nào của Ứng Dụng (bao gồm quyền truy cập vào Tài khoản của bạn và/ hoặc tính khả dụng của bất kỳ sản phẩm hoặc dịch vụ nào), khi cần thiết để thực hiện việc bảo trì thường xuyên hoặc không thường xuyên, sửa lỗi hoặc những thay đổi khác.

#### KHÔNG CÓ QUYỀN CỦA BÊN THỨ BA

Hợp Đồng này không trao quyền cho bất kỳ bên thứ ba nào không là một bên trong Hợp Đồng này.`;
