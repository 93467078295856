export const PRIVACY_POLICY_PATIENT_MD = `### BẢO MẬT THÔNG TIN
KITOR tôn trọng quyền riêng tư và cam kết bảo mật thông tin cho bệnh nhân nói riêng và người dùng nói chung. Chính sách bảo mật dưới đây nêu rõ thông tin của bệnh nhân và người dùng được thu thập và sử dụng như thế nào. Chúng tôi có thể sẽ cập nhật chính sách này nhằm tuân thủ những hướng dẫn về bảo mật mới nhất, và nếu những thay đổi này ảnh hưởng trọng yếu đến người dùng, chúng tôi sẽ thông báo và mời bạn xem xét những thay đổi. Khi bạn nhận được những thông báo này và tiếp tục sử dụng hệ thống KITOR, có nghĩa là bạn đã đồng ý với những thay đổi chúng tôi đưa ra.

Nếu bạn có bất cứ câu hỏi nào liên quan đến chính sách thu thập và sử dụng thông tin, hãy liên hệ ngay với bộ phận Bảo mật Thông tin của chúng tôi:

- Chuyên viên phụ trách Bảo mật thông tin
- Địa chỉ: 74/13/4 Trương Quốc Dung, Phường 10, Quận Phú Nhuận, TP Hồ Chí Minh.
- Email: {SUPPORTER_EMAIL} 

#### Mục đích và phạm vi thu thập

Khi sử dụng dịch vụ của KITOR trên các trang web, ứng dụng di động, và tổng đài của chúng tôi, hoặc của đối tác sử dụng phần mềm của chúng tôi theo hình thức nhãn trắng (white labeling), các loại thông tin và dữ liệu được KITOR thu thập như sau:


**Thông tin liên hệ**

- Số điện thoại, email, địa chỉ
- Mục đích:
    - Số điện thoại: Để xác thực người dùng và kết nối gọi thoại giữa bệnh nhân với bác sĩ qua tổng đài.
    - Email & Số điện thoại: Để gửi thông báo cho người dùng, nhắc hẹn và gửi các kết quả tư vấn của bác sĩ.
    - Địa chỉ: Chỉ yêu cầu nhập khi người dùng cần giao thuốc tại nhà hoặc nhận các kết quả & tài liệu qua đường bưu điện.


**Thông tin cá nhân**

- Tên, năm sinh, giới tính, thẻ bảo hiểm y tế, chứng minh nhân dân (dưới dạng số hoặc hình ảnh), chứng chỉ hành nghề
- Mục đích:
    - Để định danh người dùng trong ứng dụng.
    - Để bác sĩ đưa ra lời tư vấn phù hợp với độ tuổi và giới tính của bệnh nhân.
    - Để xác thực thông tin người dùng với bên thứ ba, ví dụ: BHYT, bệnh viện, Bộ Y tế...
    - Để đáp ứng yêu cầu của cơ quan chứng năng, ví dụ như yêu cầu bác sĩ cần phải có chứng chỉ hành nghề khi thực hành khám chữa bệnh từ xa của Bộ Y Tế.


**Thông tin sức khỏe**

- Loại thông tin: triệu chứng, đơn thuốc, chẩn đoán, chỉ số sinh tồn, chỉ số hoạt động cơ thể, kết quả xét nghiệm, kết quả chẩn đoán hình ảnh, kết quả kiểm tra sàng lọc sức khỏe, tin nhắn trao đổi với chatbot hoặc với người dùng khác. Hình thức dữ liệu: Chữ, hình ảnh, âm thanh, file ghi âm, video hoặc các tài liệu số hóa khác. 
- Mục đích:
    - Trước khi tư vấn kỹ lưỡng, bác sĩ yêu cầu bệnh nhân cung cấp đầy đủ tất cả các thông tin bệnh bệnh sử lên bệnh án dựa trên những căn cứ vững chắc nhất.
    - Lưu trữ bệnh án điện tử trọn đời & theo thứ tự thời gian giúp bác sĩ và bệnh nhân theo dõi sức khỏe tốt hơn.
    - Bệnh án đầy đủ là căn cứ để các bác sĩ hội chẩn khi cần thiết.
    - Là cơ sở để nhận chi trả của bảo hiểm.
    - Các thông tin sẽ được loại bỏ nhận dạng (xóa bỏ thông tin định danh và liên hệ) trước khi sử dụng để phân tích cải thiện các thuật toán trí tuệ nhân tạo, nâng cấp và làm cho phân mềm dễ sử dụng hơn, mang lại lợi ích thiết thực cho bác sĩ và bệnh nhân trong việc khám chữa bệnh và chăm sóc sức khỏe.


**Thông tin tài chính**

- Loại thông tin: Tài khoản ngân hàng & Lịch sử giao dịch thanh toán để sử dụng dịch vụ Chúng tôi không lưu trữ thông tin thẻ tín dụng, khi thanh toán bằng thẻ tín dụng hoặc ví điện tử, người dùng sẽ được chuyển tới trang thanh toán của các cổng thanh toán điện tử đã được nhà nước cấp phép để thực hiện giao dịch.
- Mục đích:
    - Chúng tôi lưu trữ thông tin tài khoản ngân hàng của bác sĩ để chuyển trả doanh số tư vấn, không lưu trữ thông tin tài khoản ngân hàng của bệnh nhân.
    - Chúng tôi lưu trữ lịch sử giao dịch thanh toán của khách hàng để đối soát với các cổng thanh toán điện tử.


**Thông tin kỹ thuật**

- Địa chỉ IP, thiết bị sử dụng, múi giờ, ngôn ngữ, cookies, các nội dung đã xem, kết quả tương tác với ứng dụng, thời gian truy xuất nội dung
- Mục đích:
    - Phát hiện và khắc phục các lỗi phát sinh của ứng dụng, cải thiện tốc độ và hiệu năng của ứng dụng.
    - Hỗ trợ phân tích để tối ưu trải nghiệm người dùng.


Những thông tin trên được chúng tôi thu thập từ ứng dụng KITOR, hoặc một phần từ các ứng dụng của các đối tác (ví dụ như Facebook, Google...). Dù từ bất cứ nguồn nào, chúng tôi đều tuân thủ chung một chính sách thu thập và sử dụng dịch vụ.


#### Thời gian lưu trữ thông tin

Dữ liệu cá nhân của người dùng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự người dùng đăng nhập và thực hiện hủy bỏ. Trong mọi trường hợp còn lại, thông tin cá nhân người dùng sẽ được bảo mật trên máy chủ của chúng tôi theo khuyến nghị của Bộ Y Tế


**Thông tin liên hệ**

- Thông tin được lưu trữ cho đến khi người dùng yêu cầu hủy, bỏ. Trường hợp không yêu cầu hủy, bỏ, thông tin được lưu trữ tối thiểu 10 năm kể từ khi người dùng ngừng sử dụng ứng dụng.


**Thông tin cá nhân**

- Thông tin được lưu trữ cho đến khi người dùng yêu cầu hủy, bỏ. Trường hợp không yêu cầu hủy, bỏ, thông tin được lưu trữ tối thiểu 10 năm kể từ khi người dùng ngừng sử dụng ứng dụng.


**Thông tin tài chính**

- Thông tin giao dịch lưu tối thiểu 2 năm sau ngày giao dịch để đối soát và phục vụ công tác kiểm toán.


**Thông tin y tế**

- Thông tin có gắn liền định dạng của người dùng được lưu trữ cho đến khi người dùng yêu cầu hủy, bỏ. Thông tin từ bỏ định dạng được lưu trữ ít nhất 10 năm kể từ ngày bắt đầu tồn tại. 


**Thông tin kỹ thuật**

- Thông tin từ bỏ định dạng được lưu trữ ít nhất 10 năm kể từ ngày phát sinh.


#### Lưu trữ, bảo mật, truyền dẫn và an toàn dữ liệu

- Toàn bộ thông tin của người dùng đều được mã hóa khi lưu trữ từ thiết bị của người dùng và tại máy chủ, chỉ khi điền đúng mật khẩu mới có thể truy xuất và xem được dữ liệu. Vui lòng không chia sẻ mật khẩu, các tin nhắn SMS và mã OTP của bạn cho bất cứ ai.
- KITOR không lưu trữ thông tin thẻ tín dụng và mật khẩu của người dùng vào các cổng thanh toán hoặc ứng dụng của bên thứ ba. Đối tác cổng thanh toán chúng tôi lựa chọn đều đảm bảo tuân thủ tiêu chuẩn bảo mật PCI và được nhà nước cấp phép hoạt động.
- Tất cả những thông tin truyền dẫn từ thiết bị người dùng đến hệ thống của chúng tôi, giữa hệ thống của chúng tôi với hệ thống của bên thứ ba đều được mã hóa dùng SSL và các tiêu chuẩn bảo mật mới nhất để hạn chế các truy cập bất hợp pháp.
- Dữ liệu của người dùng được lưu trữ và dự phòng tại các Trung tâm dữ liệu lớn tại Việt Nam, Singapore và Mỹ.
- Chúng tôi không sử dụng, không chuyển giao, không cung cấp hay tiết lộ cho bất cứ bên thứ 3 nào khi chưa có sự đồng ý từ người dùng.
- Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân của người dùng, chúng tôi sẽ thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho người dùng được biết.


#### Quyền người dùng với dữ liệu

- Quý khách hàng có quyền truy cập vào dữ liệu riêng của mình và tải dữ liệu về thiết bị riêng (trừ trường hợp quá thời gian lưu trữ hoặc trường hợp dữ liệu đồng sở hữu bởi người dùng khác, ví dụ như file ghi âm).
- Đề nghị chỉnh sửa dữ liệu nếu có sai sót hoặc xóa dữ liệu. Riêng đối với những dự liệu là cơ sở cho các nội dung tư vấn & chẩn đoán hay toa thuốc, dữ liệu sẽ được từ bỏ định dạng nhưng không được xóa sửa cho đến khi hết thời gian lưu trữ tối thiểu quy định.
- Thiết lập và thay đổi thiết lập quyền sử dụng thông tin & dữ liệu.`;
