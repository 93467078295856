import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { setupLayouts } from "virtual:generated-layouts";
import generatedRoutes from "@/routes/generatedRoutes";
const routes: RouteRecordRaw[] = setupLayouts(generatedRoutes);
const router = createRouter({
  // history: createWebHistory(import.meta.env.VITE_BASE_URL),
  history: createWebHistory("/"),
  routes
});
// TODO: Register middleware
export default router;
