import { useRoute, useRouter } from "vue-router";
import { useQuasar, QSpinnerIos } from "quasar";

export const useEnhancer = () => {
  const $q = useQuasar();
  const route = useRoute();
  const router = useRouter();

  // const { t, locale, availableLocales } = useI18n()

  const showNotify = (message: string, type: any = "positive") => {
    $q.notify({
      type,
      position: "top-right",
      message
    });
  };

  const showGlobalLoading = (message = "Vui lòng chờ 1 xíu.") => {
    $q.loading.show({
      spinner: QSpinnerIos,
      spinnerColor: "white",
      message
    });
  };

  const hideGlobalLoading = () => {
    $q.loading.hide();
  };

  return {
    route,
    router,
    // t,
    // locale,
    // availableLocales,
    showNotify,
    showGlobalLoading,
    hideGlobalLoading,
    showDialog: $q.dialog
  };
};
