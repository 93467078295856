<template>
  <div class="page">
    <div class="header bg-white">
      <q-toolbar class="q-pa-none">
        <q-img src="src/assets/png/logo.png" class="logo" />
        <q-space />
        <q-btn
          stretch
          no-caps
          flat
          @click="scrollTo(null)"
          label="Dịch vụ"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="scrollTo('guide')"
          stretch
          no-caps
          flat
          label="Hướng dẫn"
          class="text-weight-regular header-btn"
        />
        <!-- <q-btn
          @click="scrollTo('price')"
          stretch
          no-caps
          flat
          label="Bảng giá"
          class="text-weight-regular header-btn"
        /> -->
        <q-btn
          @click="termDialog = true"
          stretch
          no-caps
          flat
          label="Chính sách"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="linkto('home')"
          stretch
          no-caps
          flat
          label="Công ty"
          class="text-weight-regular header-btn q-mr-md"
        />
        <q-btn
          color="primary"
          @click="clickDownload"
          no-caps
          label="Tải ứng dụng"
          class="header-btn"
        />
        <q-btn-dropdown
          class="display-mobile"
          color="primary"
          flat
          dropdown-icon="menu"
        >
          <div class="row no-wrap q-pa-md">
            <q-list>
              <q-item @click="scrollTo(null)" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Dịch vụ</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="scrollTo('guide')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Hướng dẫn</q-item-label>
                </q-item-section>
              </q-item>
              <!-- <q-item @click="scrollTo('price')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Bảng giá</q-item-label>
                </q-item-section>
              </q-item> -->
              <q-item @click="termDialog = true" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Chính sách</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="linkto('home')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Công ty</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="clickDownload" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Tải ứng dụng</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-btn-dropdown>
      </q-toolbar>
    </div>
    <div class="body flex justify-center row">
      <div>
        <div class="slogan-content flex flex-center justify-center">
          <div class="slogan-content-body q-mx-xl">
            <p class="slogan-content-text-title">Giữ liên lạc với bác sĩ</p>
            <p class="slogan-content-text-body">
              Nhận được sự chăm sóc liên tục, chất lượng từ chính bác sĩ điều
              trị của bạn thông qua
              <span class="text-bolder">thăm khám từ xa.</span>
            </p>
            <div class="slogan-div-btn display-desktop">
              <q-btn
                color="primary"
                @click="clickDownload"
                no-caps
                rounded
                icon-right="arrow_right_alt"
                label="Tải ứng dụng để kết nối với bác sĩ"
                class="text-bolder q-px-lg q-py-md q-mt-md slogan-btn"
              />
            </div>
            <div class="slogan-div-btn display-mobile">
              <q-btn
                color="primary"
                @click="clickDownload"
                no-caps
                rounded
                icon-right="arrow_right_alt"
                label="Kết nối với bác sĩ"
                class="text-bolder q-px-lg q-py-md q-mt-md slogan-btn"
              />
            </div>
          </div>
        </div>
      </div>
      <q-img
        fit="contain"
        src="src/assets/png/image1.png"
        class="slogan-image q-ml-xl"
      />
    </div>
    <div class="flex flex-center service-wrap">
      <q-card class="card-services">
        <q-card-section
          class="flex flex-center text-bolder card-title q-pb-none"
        >
          Tại sao khám bệnh từ xa
        </q-card-section>
        <q-card-section
          class="flex flex-center q-px-xl q-py-none service-wrap-content"
        >
          <div class="full-width service-content">
            <p class="q-px-lg service-wrap-image">
              <q-img
                fit="contain"
                src="src/assets/png/image2.png"
                class="service-image"
              />
            </p>
            <div class="case-content-body self-center q-gutter-lg">
              <p class="row">
                <q-icon size="md" name="history" class="dot" />
                <span class="case-body col"
                  >Giảm thời gian di chuyển và chờ đợi tại phòng khám.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="masks" class="dot" />
                <span class="case-body col"
                  >Tránh được nguy cơ nhiễm trùng phòng khám.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="sync" class="dot" />
                <span class="case-body col"
                  >Nhận được sự chăm sóc liên tục, chất lượng từ chính bác sĩ
                  chữa trị của mình.</span
                >
              </p>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card class="card-services">
        <q-card-section
          class="flex flex-center text-bolder card-title q-pb-none"
        >
          Sử dụng dịch vụ khi nào
        </q-card-section>
        <q-card-section
          class="flex flex-center q-px-xl q-py-none service-wrap-content"
        >
          <div class="full-width service-content">
            <p class="q-px-lg service-wrap-image">
              <q-img
                fit="contain"
                src="src/assets/png/image3.png"
                class="service-image"
              />
            </p>
            <div class="case-content-body self-center q-gutter-lg">
              <p class="row">
                <q-icon size="md" name="replay" class="dot" />
                <span class="case-body col"
                  >Tái khám với bác sĩ đang điều trị.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="connect_without_contact" class="dot" />
                <span class="case-body col"
                  >Cần nhận lời tư vấn về các vấn đề y khoa như triệu chứng, quy
                  trình...</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="block" class="dot" />
                <span class="case-body col"
                  >Không phù hợp cho cấp cứu hoặc các thăm khám cần các thiết bị
                  chuyên sâu.</span
                >
              </p>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div ref="guideRef" class="flex flex-center bg-white section-3">
      <div
        class="flex flex-center text-bolder card-title q-pb-md full-width step-title"
      >
        4 bước tiến hành thăm khám từ xa
      </div>
      <q-card class="card-section-3 row">
        <div
          class="col-6 flex flex-center justify-center step-section-image display-mobile"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step1.png"
            class="step-image"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">01</div>
          <div class="card-subtitle">Kết nối với bác sĩ điều trị</div>
          <span>
            Giữ danh thiếp của bác sĩ sau khi thăm khám tại cơ sở y tế. Quét mã
            QR code trên danh thiếp để kết nối với bác sĩ.
          </span>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-desktop"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step1.png"
            class="step-image"
          />
        </div>
        <div class="col-6 flex flex-center justify-center step-section-image">
          <q-img
            fit="contain"
            src="src/assets/png/step2.png"
            class="step-image step-image-2"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">02</div>
          <div class="card-subtitle">Soạn yêu cầu tới bác sĩ</div>
          <span>
            Sử dụng ứng dụng mô tả câu hỏi, vấn đề của bạn và gửi tới bác sĩ để
            xác minh
          </span>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-mobile"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step3.png"
            class="step-image"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">03</div>
          <div class="card-subtitle">Thanh toán, đặt hẹn</div>
          <span>
            Sau khi bác sĩ xác nhận tư vấn, bạn tiến hành thanh toán trực tuyến
            MOMO và đặt lịch hẹn . Bác sĩ có thể yêu cầu bạn cung cấp thêm thông
            tin y khoa nếu cần.
          </span>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-desktop"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step3.png"
            class="step-image"
          />
        </div>
        <div class="col-6 flex flex-center justify-center step-section-image">
          <q-img
            fit="contain"
            src="src/assets/png/step4.png"
            class="step-image"
            style="position: relative; bottom: -20px"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">04</div>
          <div class="card-subtitle">Thăm khám trực tuyến</div>
          <span>
            Tiến hành thăm khám trực tuyến với bác sĩ theo lịch đã chọn. Sau
            buổi thăm khám trực tuyến, bác sĩ sẽ tổng hợp và gửi các hướng dẫn y
            khoa cho bạn. Kết thúc phiên thăm khám.
          </span>
        </div>
      </q-card>
    </div>

    <q-card class="footer">
      <div class="flex justify-between">
        <q-img src="src/assets/png/logo-footer.png" class="footer-logo" />
        <div class="flex justify-between">
          <div class="q-px-md flex justify-between q-gutter-lg">
            <div
              @click="clickTerm"
              class="text-right text-bold text-primary cursor-pointer self-center mobile-term"
            >
              Điều khoản sử dụng dịch vụ
            </div>
            <div
              @click="clickPrivacy"
              class="text-right text-bold text-primary cursor-pointer self-center mobile-term"
            >
              Chính sách bảo mật
            </div>
          </div>
          <div class="flex justify-between">
            <q-img
              @click="linkto('google')"
              src="src/assets/png/google-play.png"
              class="footer-logo cursor-pointer"
            />
            <q-img
              @click="linkto('apple')"
              src="src/assets/png/app-store.png"
              class="footer-logo cursor-pointer"
            />
          </div>
        </div>
      </div>
      <q-separator />
      <div
        class="flex justify-between text-caption text-grey-8 footer-2 q-mt-md"
      >
        <div
          @click="linkto('home')"
          style="width: 45%"
          class="cursor-pointer wrap-copy-right"
        >
          © 2022 Spring Knowledge Global Joint Stock Company. All rights
          reserved
        </div>
        <div class="text-center wrap-email" style="width: 19%">
          <q-icon name="email" />
          <a
            style="text-decoration: none"
            class="text-grey-8"
            href="mailto:support@kitor.vn"
            >support@kitor.vn</a
          >
        </div>
        <div
          @click="linkto('maps')"
          class="text-right wrap-address cursor-pointer"
          style="width: 36%"
        >
          <q-icon name="pin_drop" />74/13/4 Trương Quốc Dung, P.10, Phú Nhuận,
          TpHCM
        </div>
      </div>
    </q-card>
  </div>

  <q-dialog v-model="termDialog">
    <q-card style="max-width: 70vw">
      <q-card-section class="q-pa-lg">
        <div style="position: absolute; right: 10px; top: 10px">
          <q-btn
            size="0.6rem"
            outline
            round
            @click="termDialog = false"
            icon="close"
          />
        </div>
        <div class="q-mb-md">
          <div v-html="renderTerm"></div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="privacyDialog">
    <q-card style="max-width: 70vw">
      <q-card-section class="q-pa-lg">
        <div style="position: absolute; right: 10px; top: 10px">
          <q-btn
            size="0.6rem"
            outline
            round
            @click="privacyDialog = false"
            icon="close"
          />
        </div>
        <div class="q-mb-md">
          <div v-html="renderPrivacy"></div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from "vue";
import { useQuasar } from "quasar";
import gsheet from "src/services/google-sheet";
import { useEnhancer } from "@/app/enhancer";
import { PRIVACY_POLICY_PATIENT_MD } from "@/constants/privacy_policy_patient";
import { TERMS_OF_SERVICE_PATIENT_MD } from "@/constants/terms_of_service_patient";
import MarkDownIt from "markdown-it";

export default defineComponent({
  name: "RootPage",
  setup() {
    const initialState = {
      privacyDialog: false,
      renderPrivacy: "",
      renderTerm: "",
      termDialog: false,
      downloadDialog: false,
      loading: false,
      name: "",
      company: "",
      email: "",
      text: "",
      services: []
    };
    const guideRef = ref(null);
    const state = reactive({ ...initialState });

    const { router } = useEnhancer();
    const $q = useQuasar();

    const linkto = (link) => {
      if (link === "home") window.open("https://sk-global.biz/");
      if (link === "maps") window.open("https://goo.gl/maps/WqufioBCB9eZbJNk7");
      if (link === "apple")
        window.open("https://apps.apple.com/vn/app/kitor/id6446671627");
      if (link === "google")
        window.open(
          "https://play.google.com/store/apps/details?id=skglobal.prehr.patientMobile"
        );
    };
    const scrollTo = (refClicked) => {
      let top = 0;
      if (refClicked === "guide") top = guideRef.value.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    };

    const clickDownload = () => {
      console.log(navigator.userAgent);
      const userAgent = navigator.userAgent;
      if (userAgent.match(/(Mac|iPhone|iPod|iPad)/i)) {
        linkto("apple");
      } else {
        linkto("google");
      }
    };

    const clickPrivacy = async () => {
      state.privacyDialog = true;
      const md = new MarkDownIt();
      state.renderPrivacy = await md.render(PRIVACY_POLICY_PATIENT_MD);
    };

    const clickTerm = async () => {
      state.termDialog = true;
      const md = new MarkDownIt();
      state.renderTerm = await md.render(TERMS_OF_SERVICE_PATIENT_MD);
    };

    return {
      guideRef,
      clickDownload,
      ...toRefs(state),
      linkto,
      scrollTo,
      clickPrivacy,
      clickTerm
    };
  }
});
</script>

<style lang="scss">
@import "src/assets/app.scss";
</style>
