


export const layouts = {
'blank': () => import('/Users/tamnguyen/Documents/Projects/prehr-landingpage/src/layouts/blank.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
